import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
const path = require('path')

export default class PortfolioPage extends Component {
  componentDidMount () {
    const link = document.createElement('a')
    link.href = path.resolve(
      `img/${
        this.props.data.home.edges[0].node.frontmatter.portfolio_pdf
          .relativePath
      }`
    )
    link.download = 'stolfo_paolo_portfolio.pdf'
    link.dispatchEvent(new MouseEvent('click'))

    document.getElementById('link').dispatchEvent(new MouseEvent('click'))
  }
  render () {
    return <Link id='link' to='/' hidden />
  }
}

export const PortfolioPageQuery = graphql`
  query PortfolioPagequery {
    home: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } } }
    ) {
      edges {
        node {
          frontmatter {
            portfolio_pdf {
              relativePath
            }
          }
        }
      }
    }
  }
`
